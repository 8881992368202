@import "include/colors";
@import "include/fonts";
@import "mixins/rounded";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    background-clip: content-box !important;
}
.apps {
	position: absolute;
	right: 0;
	padding: 10px;
	z-index: 20;
}

body[data-app-version]:not([data-app-version=""]) {
	.apps {
		display: none;
	}
}

body {
	position: relative;
}

.information {
	a {
        text-decoration: none;
        transition: color 0.3s;
    }
	text-align: center;
	font-weight: 200;
	font-family: $font;
}

.lostpassword {
	margin-top: 10px;
	padding: 10px;
	a {
		text-decoration: none;
	}
	font-weight: 200;
	font-family: $font;
}

main {
	min-height: calc(100vh - 60px);
	&#main-login {
		.oauth {
			margin-bottom: 60px;
			a {
				text-align: left;
				text-decoration: none;
				margin-bottom: 10px;

				@media screen and (max-width: 768px) {
					display: block;
				}
			}
			h2 {
				text-align: center;
				padding-bottom: 10px;
			}
		}

		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		#terms {
			h2 {
				font-size:24px;
				margin-bottom:10px;
			}
			p {
				margin: 40px 0;
			}
		}


		.message {
			font-weight: 200;
			padding: 10px;
			max-width: 400px;
		}

		#compatibilityWarning {
			display: none;
		}

		#icon {
			@include rounded;
			margin-left: auto;
			margin-right: auto;
			display: block;
			width: 120px;
		}

		form#login, form#lostPassword {
			display: none;
		}

		form#login, form#lostPassword, #zones {
			.name {
				padding-top: 10px;
				font-size: 1.8em;
				text-align: center;
				font-family: $font;
			}

			h2 {
				text-align: center;
				padding: 5px;
				font-family: $font;
			}

			margin-bottom: 50px;

			#autologin {
				display: inline-block;
			}

			label[for=autologin] {
				padding-left: 3px;
				font-size: 14px;
			}

			.login-password {
				display: none;
			}

			input {
				&.login-password {
					display: none;
				}

				display: block;
				width: 100%;
				font-size: 14px;
				margin-top: 10px;
				margin-left: auto;
				margin-right: auto;

				&[type=email],&[type=password],&[type=text] {
					border-radius: 0;
					background-color: transparent;
					outline: none;
					border: 0px solid;
					padding:5px;
					margin-bottom:10px;
				}

				&[type=checkbox] {
					width: auto;
					margin-right: 5px;
				}

				&[type=submit] {
					min-width: 123px;
					width: auto;
					margin-left: auto;
					margin-right: auto;
					margin-top: 20px;
				}

				&[name="oldBrowser"] {
					display: none;
				}
			}

			> div {
				text-align: center;

				.cards {
					text-align: left;
				}
			}

			a.btn {
				text-decoration: none;
			}

		}

		form#lostPassword {
			display: block;
		}

	}

	.maintenance {
		display: flex;
		flex-direction: row;
		border-radius: 5px;
		margin: 10px 20px 5px 20px;
		padding: 5px 20px;
		color: $warningTextColor;
		border: 1px solid $warningLineColor;
		background: $warningBackgroundColor;

		.message {
			color: $warningTextColor;
			padding-left: 15px;
			align-self: center;
		}
	}

	.warning {
		display: flex;
		flex-direction: row;
		border-radius: 5px;
		margin: 10px 20px 5px 20px;
		padding: 5px 20px;
		color: $errorTextColor;
		border: 1px solid $errorLineColor;
		background: $errorBackgroundColor;

		.swal2-error {
			zoom: 0.5;
			display: flex;
		}

		.message {
			color: $errorTextColor;
			padding-left: 15px;
			align-self: center;
		}
	}

	.success {
		display: inline-flex;
		flex-direction: row;
		border-radius: 5px;
		margin: 10px 20px 5px 20px;
		padding: 5px 20px;
		color: $successTextColor;
		border: 1px solid $successLineColor;
		background: $successBackgroundColor;

		.swal2-success {
			zoom: 0.5;
			display: flex;
		}

		.message {
			color: $successTextColor;
			padding-left: 15px;
			align-self: center;
		}
	}
}
